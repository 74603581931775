.dash {
  background-color: white;
  padding: 15px;
}
.dash .line {
  margin-bottom: 50px;
}
.dash .ant-row {
  flex: 1;
}

.ant-menu-item > a {
  display: flex !important;
  align-items: center;
}
.ant-menu-item > a:hover {
  color: #ef1b0b !important;
}
.ant-menu-item .icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
}
.ant-menu-item.home.ant-menu-item-selected {
  background-color: white !important;
}
.ant-menu-item.home.ant-menu-item-selected::after {
  border-right: 0;
}
.ant-menu-item.home.ant-menu-item-selected .icon {
  background: url("/img/home_s.png") no-repeat;
  background-size: 20px 20px;
}
.ant-menu-item.home .icon {
  background: url("/img/home.png") no-repeat;
  background-size: 20px 20px;
}
.ant-menu-submenu.ant-menu-submenu-active .ant-menu-submenu-title:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-submenu.ant-menu-submenu-active .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu.ant-menu-submenu-active .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
  background: linear-gradient(to right, #ef1b0b, #ef1b0b);
}
.ant-menu-submenu.ant-menu-submenu-active .ant-menu-submenu-arrow::after {
  background-color: rgba(0, 0, 0, 0.85);
}
.ant-menu-submenu.ant-menu-submenu-selected .ant-menu-submenu-title > div.driver .icon {
  background: url("/img/menu_driver.svg") no-repeat;
  background-size: 18px 18px;
}
.ant-menu-submenu.ant-menu-submenu-selected .ant-menu-submenu-title > div.order .icon {
  background: url("/img/ico_order_pressed.svg") no-repeat;
  background-size: 18px 18px;
}
.ant-menu-submenu.ant-menu-submenu-selected .ant-menu-submenu-title > div.business .icon {
  background: url("/img/ico_shop_s.svg") no-repeat;
  background-size: 18px 18px;
}
.ant-menu-submenu.ant-menu-submenu-selected .ant-menu-submenu-title > div.delivery-point .icon {
  background: url("/img/menu_order_sel.svg") no-repeat;
  background-size: 18px 18px;
}
.ant-menu-submenu.ant-menu-submenu-selected .ant-menu-submenu-title > div.line .icon {
  background: url("/img/yd_sel.svg") no-repeat;
  background-size: 18px 18px;
}
.ant-menu-submenu.ant-menu-submenu-selected .ant-menu-submenu-title > div.control .icon {
  background: url("/img/user_sel.svg") no-repeat;
  background-size: 18px 18px;
}
.ant-menu-submenu .ant-menu-submenu-title > div {
  display: flex;
  align-items: center;
}
.ant-menu-submenu .ant-menu-submenu-title > div.driver .icon {
  background: url("/img/menu_driver.svg") no-repeat;
  background-size: 18px 18px;
}
.ant-menu-submenu .ant-menu-submenu-title > div.order .icon {
  background: url("/img/menu_order.svg") no-repeat;
  background-size: 18px 18px;
}
.ant-menu-submenu .ant-menu-submenu-title > div.business .icon {
  background: url("/img/ico_shop.svg") no-repeat;
  background-size: 18px 18px;
}
.ant-menu-submenu .ant-menu-submenu-title > div.delivery-point .icon {
  background: url("/img/home_o.svg") no-repeat;
  background-size: 18px 18px;
}
.ant-menu-submenu .ant-menu-submenu-title > div.line .icon {
  background: url("/img/yd.svg") no-repeat;
  background-size: 18px 18px;
}
.ant-menu-submenu .ant-menu-submenu-title > div.control .icon {
  background: url("/img/user.svg") no-repeat;
  background-size: 18px 18px;
}
.ant-menu-submenu .ant-menu-submenu-title > div .icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
}
.ant-btn.quit {
  border-radius: 10px;
  border: 1px solid white;
  font-size: 12px;
  width: 36px;
  height: 20px;
  text-align: center;
  padding: 0;
  margin-left: 10px;
}
.ant-btn.quit:hover {
  border: 1px solid white;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid white !important;
}

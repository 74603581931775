@import '~antd/dist/antd.css';
.App {
  text-align: center;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}
.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}
.App-title {
  font-size: 1.5em;
}
.App-intro {
  font-size: large;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ant-layout-header {
  color: white;
  background-color: #ef1b0b;
  height: 54px;
  display: flex;
  align-items: center;
  padding: 0 24px;
}
.ant-layout-header img {
  margin-right: 10px;
}
.ant-layout-header .action {
  flex: 1;
  text-align: right;
}
.ant-layout-sider {
  background-color: white;
}
.ant-layout-footer {
  text-align: center;
}
.ant-layout-content .welcome {
  padding: 24px 104px;
  background: white url('/img/bg.png') no-repeat 24px top;
  backgroup-size: 96px 80px;
}
.ant-layout-content .welcome h3 {
  font-size: 24px;
  line-height: 32px;
  color: #000000d9;
}
.ant-layout-content .welcome p {
  font-size: 14px;
  line-height: 22px;
  color: #000000d9;
  margin-bottom: 0;
}
.ant-layout-content .dashboard.content {
  margin-top: 16px;
  display: flex;
}
.ant-layout-content .dashboard.content .title {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding: 20px 24px;
}
.ant-layout-content .dashboard.content .sts {
  flex: 1;
}
.ant-layout-content .dashboard.content .sts .card {
  background-color: white;
  margin-bottom: 16px;
  border-radius: 4px;
}
.ant-layout-content .dashboard.content .sts .card .statistic {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0 60px;
}
.ant-layout-content .dashboard.content .sts .card .statistic .item {
  flex-grow: 1;
  text-align: center;
}
.ant-layout-content .dashboard.content .sts .card .statistic .item span {
  display: block;
  font-size: 48px;
  line-height: 50px;
  text-align: center;
  margin-bottom: 10px;
}
.ant-layout-content .dashboard.content .sts .card .statistic .item span.gray {
  color: rgba(0, 0, 0, 0.25);
}
.ant-layout-content .dashboard.content .sts .card .statistic .item span.red {
  color: #ef1b0b;
}
.ant-layout-content .dashboard.content .sts .card .statistic .item span.blue {
  color: #1890ff;
}
.ant-layout-content .dashboard.content .sts .card .statistic .item label {
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.65);
}
.ant-layout-content .app {
  width: 334px;
  background-color: white;
  margin-left: 16px;
  border-radius: 4px;
  height: 504px;
  box-sizing: border-box;
}
.ant-layout-content .app .c img {
  width: 200px;
  height: 200px;
  margin: 70px auto 40px;
  display: block;
  border: 1px solid #e1e1e1;
}
.ant-layout-content .app .c p {
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
  font-size: 14px;
}
.ant-menu-submenu-selected {
  color: #ef1b0b;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(253, 63, 112, 0.1);
}
.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
  color: #ef1b0b;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #ef1b0b;
}
.ant-pagination {
  color: #999999;
}
.ant-pagination .ant-pagination-item-active {
  border: 1px solid #ef1b0b;
}
.ant-pagination .ant-pagination-item-active a {
  color: #ef1b0b;
}
.ant-btn-primary {
  border: none;
}
.ant-btn-primary:hover {
  background-color: #ef1b0b;
  border: none;
}
.ant-btn-primary:active {
  background-color: #ef1b0b;
  border: none;
}
.ant-btn-primary.ant-btn-dangerous {
  background-color: #ef1b0b;
}
.ant-breadcrumb {
  font-size: 12px;
  color: rgba(51, 51, 51, 0.45);
}
.ant-breadcrumb > span:last-child {
  color: rgba(51, 51, 51, 0.65);
}
h3 {
  padding: 8px 0 16px;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 28px;
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: rgba(239, 27, 11, 0.1);
}
.ant-table-row.ant-table-row-level-0.ant-table-row-selected:hover {
  background-color: rgba(239, 27, 11, 0.1);
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: rgba(239, 27, 11);
}
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background-color: rgba(239, 27, 11, 0.1);
}
.ant-checkbox:hover,
.ant-checkbox:focus {
  background-color: rgba(239, 27, 11, 0.1);
}
.ant-checkbox-checked .ant-checkbox-inner {
  color: #ef1b0b;
  background-color: #ef1b0b;
  border: 1px solid #ef1b0b;
}
.ant-checkbox-checked .ant-checkbox-inner:hover {
  color: #ef1b0b;
  background-color: #ef1b0b;
  border: 1px solid #ef1b0b;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ef1b0b;
}
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus {
  border-color: #ef1b0b;
  box-shadow: 0 0 0 2px rgba(239, 27, 11, 0.1);
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #ef1b0b;
  box-shadow: 0 0 0 2px rgba(239, 27, 11, 0.1);
}
table tr td {
  color: rgba(51, 51, 51, 0.65);
}
table a {
  color: #ef1b0b;
}
.ant-btn-link.primary-color {
  color: #ef1b0b;
}
.main-content {
  background-color: white;
  padding-top: 30px;
  min-height: 640px;
}
.padding {
  padding: 10px 15px 10px;
}
